import React from 'react';
import PropTypes from 'prop-types';

import './UserAction.scss';

const UserAction = ({onClick, text}) => {
  return (
      <div className="UserAction"
           onClick={onClick}>
        {text}
      </div>
  );
};

UserAction.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired
};

export default UserAction;