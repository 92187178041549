import React, { useState, useEffect, useRef } from 'react';
import { Link, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import ApplicationLogo from '../../../components/ApplicationLogo/ApplicationLogo';
import BrowseNavigation from '../../../components/BrowseNavigation/BrowseNavigation';
import MainSearch from '../../../components/MainSearch/MainSearch';
import UserAction from '../../../components/UserAction/UserAction';
import Arrow from '../../../components/Arrow/Arrow';
import { PROPERTY_ID, RenderConditionalComponent } from '../../../components/RenderConditionalComponent/RenderConditionalComponent';
import { ReactComponent as AgeVerifiedIcon } from '../../../images/ageVerifiedIcon.svg';

import { getFreeRoute, getOriginalsPageRoute, getTop10PageRoute, routes } from '../../../services/navigation/navigation.service.routes';
import { createJoinLinkFromLastCode, links } from '../../../links';
import { isUserLoggedIn } from '../../../services/token-service/token.service';
import { getUserNATSCode } from '../../../services/nats-service/nats.service';
import { UsersController } from '../../../controllers/users-controller/users.controller';
import { showMyAccountPage } from '../../../services/navigation/navigation.service';
import { SignInController } from '../../../controllers/sign-in-controller/sign-in.controller';
import { getCookie, isWeb } from '../../../services/util-service/util.service';

import './MainDashboardHeader.scss';

const MainDashboardHeader = (props) => {
  const {
    browseConfig = [],
    starsPageEnabled,
    searchOptions,
    handleSearchDropdownValue,
    toggleMobileMenu
  } = props;

  const [desktopSearchVisible, setDesktopSearchVisible] = useState(false);
  const ageVerContentRef = useRef(null);
  const ageVerVisible = useRef(false);
  const { t } = useTranslation();
  const userLoggedIn = isUserLoggedIn();

  useEffect(() => {
    const ageVerifiedClickOutside = (event) => {
      if (isWeb()) return;
      if (ageVerContentRef.current) {
        const elem = event.target;

        if (elem.classList.contains('AgeVerifiedContainer')) {
          ageVerVisible.current = !ageVerVisible.current;
          ageVerContentRef.current.style.display = ageVerVisible.current ? 'block' : 'none';
        } else {
          ageVerContentRef.current.style.display = 'none';
          ageVerVisible.current = false;
        }
      }
    };
    document.addEventListener('click', ageVerifiedClickOutside);

    return () => {
      document.removeEventListener('click', ageVerifiedClickOutside);
    }
  }, []);

  const renderNav = () => {
    const navLinks = "NavBar-Btn";

    return (
        <nav className='NavBar d-flex align-center'>
          {!desktopSearchVisible ?
              <>
                {browseConfig?.length !== 0 && (
                    <BrowseNavigation
                        className={navLinks}
                        browseConfig={browseConfig}
                        starsPageEnabled={starsPageEnabled}
                        baseText={t('BrowseNavigation.browse')}
                    />
                )}

                <Link className={navLinks}
                      to={routes.justAdded}
                      data-text={t('MainDashboardHeader.justAdded')}>
                  {t('MainDashboardHeader.justAdded')}
                </Link>

                <RenderConditionalComponent ids={[PROPERTY_ID.nakedSword, PROPERTY_ID.guerillaPorn, PROPERTY_ID.trentonDucati]}>
                  <Link className={navLinks}
                        to={getTop10PageRoute()}
                        data-text={t('MainDashboardHeader.top10')}>
                    {t('MainDashboardHeader.top10')}
                  </Link>
                </RenderConditionalComponent>

                <RenderConditionalComponent ids={[PROPERTY_ID.nakedSword, PROPERTY_ID.guerillaPorn, PROPERTY_ID.huntForMen]}>
                  <Link
                      className={navLinks}
                      key="originals"
                      to={getOriginalsPageRoute()}
                      data-text={t('MainDashboardHeader.originals')}>
                    {t('MainDashboardHeader.originals')}
                  </Link>
                </RenderConditionalComponent>

                <RenderConditionalComponent ids={[PROPERTY_ID.trentonDucati]}>
                  <BrowseNavigation
                      className={navLinks}
                      browseConfig={[
                        {
                          link: routes.trentonducatioriginals,
                          text: t("MainDashboardHeader.tdoriginals")
                        },
                        {
                          link: getOriginalsPageRoute(),
                          text: t('MainDashboardHeader.nsoriginals')
                        }]}
                      baseText={t('MainDashboardHeader.originals')}
                  />
                </RenderConditionalComponent>

                <RenderConditionalComponent ids={[PROPERTY_ID.huntForMen]}>
                  <a className={navLinks}
                     href={links.zine}
                     rel="noopener noreferrer"
                     key="zine"
                     target="_blank">
                    HUNT eZine
                  </a>
                </RenderConditionalComponent>

                <RenderConditionalComponent ids={[PROPERTY_ID.guerillaPorn, PROPERTY_ID.nakedSword]}>
                  <Link
                      className={navLinks}
                      to={getFreeRoute()}
                      data-text={t('MainDashboardHeader.free')}>
                    {t('MainDashboardHeader.free')}
                  </Link>
                </RenderConditionalComponent>
              </>
              : <i className="HeaderNavCollapseIndicator fas fa-bars" />
          }

          {renderSearch()}
        </nav>
    )
  };

  const renderSearch = () => {
    return (
        <MainSearch
            toggleSearchFn={val => setDesktopSearchVisible(!val)}
            searchOptions={searchOptions}
            handleSearchDropdownValue={handleSearchDropdownValue}
        />
    )
  };

  const renderUserActions = () => {
    return (
        <div className="UserActions">
          {userLoggedIn ? (
              <UserAction text={t('MainDashboardHeader.signOut')} onClick={() => UsersController.signOutUser(t('MainDashboardHeader.loginOutMsg'))} />
          ) : (
              <UserAction text={t('MainDashboardHeader.joinNow')} onClick={() => {
                // get latest valid code
                const getStoredCode = getUserNATSCode();
                const joinURL = createJoinLinkFromLastCode(getStoredCode);
                navigate(joinURL);
              }} />
          )}
          {userLoggedIn && <div className="Separator">|</div>}
          {userLoggedIn ? (
              <UserAction text={t('MainDashboardHeader.myAccount')} onClick={() => showMyAccountPage()} />
          ) : (
              <div onClick={() => SignInController.showSignInDialog()} className="LoginWrapper">
                <UserAction text={t('MainDashboardHeader.login')} />
                <Arrow />
              </div>
          )}
        </div>
    )
  };

  const renderMobileSearch = () => {
    if (!desktopSearchVisible || isWeb()) return null;
    return <div className="MobileSearchWrapper">{renderSearch()}</div>
  };

  const renderAgeVerIcon = () => {
    let bavCookie = getCookie('bav');
    if (!bavCookie) return null;

    try {
      bavCookie = decodeURIComponent(bavCookie);
      bavCookie = window.atob(bavCookie);
    } catch (err) {
      console.error('Decoding bav failed:', err);
    }
    const bavToLocalFormat = new Date(bavCookie * 1000).toLocaleString(); // timestamp  
    const validBavDate = bavToLocalFormat !== 'Invalid Date' ? bavToLocalFormat : '';

    return (
        <span className='AgeVerifiedContainer'>
        <AgeVerifiedIcon />
        <div className='AgeVerifiedContent' ref={ageVerContentRef}>
          <div>Age verification successful.</div>
          <div>Verification valid until:</div>
          <div>{validBavDate}</div>
        </div>
      </span>
    )
  };

  const renderMobileIcons = () => {
    return (
        <>
          <i className="MobileSearchBtn fa fa-search" onClick={() => {
            if (SignInController.isSignInDisplayed()) {
              SignInController.closeSignInDialog();
            }
            setDesktopSearchVisible(prevState => !prevState);
          }} />

          <i className="MobileMenuBtn fa fa-bars cursor-pointer" onClick={() => {
            if (toggleMobileMenu()) {
              toggleMobileMenu();
            }
            setDesktopSearchVisible(false);
          }} />
        </>
    )
  };

  return (
      <header className='MainDashboardHeader d-flex align-center'>
        <div className='d-flex align-center'>
          <ApplicationLogo />
          {renderNav()} {/* hidden in mobile view*/}
        </div>

        <div className='d-flex align-center'>
          {renderUserActions()}  {/* hidden in mobile view*/}
          {renderMobileIcons()}
          {renderAgeVerIcon()}
        </div>

        {renderMobileSearch()}
      </header>
  )
};

MainDashboardHeader.displayName = 'MainDashboardHeader';

export default MainDashboardHeader;